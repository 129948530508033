<ng-container *transloco="let t; prefix: 'login'">
  <div class="login">
    <div [ngClass]="{'is-modal': isModal}" class="login-title">
      <span class="heading-s" data-cy="pre-title"
        ><strong>{{ t('privateAccess') }}</strong></span
      >
      <h1 class="heading-xl" data-cy="title">{{ isModal ? t('login') : t('welcomeBack') }}</h1>
      <h2 class="heading-l" data-cy="subtitle">{{ t('enterEmailAndPassword') }}</h2>
    </div>
    <form [formGroup]="loginForm" class="form" data-cy="form">
      <div class="form-field underlined form-error">
        <input
          data-cy="email"
          formControlName="email"
          placeholder="{{ t('email') }}"
          [attr.aria-label]="t('email')"
          type="email"
        />
      </div>

      <div class="form-field underlined form-error">
        <input
          formControlName="password"
          placeholder="{{ t('password') }}"
          [attr.aria-label]="t('password')"
          [type]="showPassword ? 'text' : 'password'"
          data-cy="password"
          formControlName="password"
          placeholder="{{ t('password') }}"
        />
        <button (click)="showPassword = !showPassword" class="button-content" type="button">
          <i [innerHTML]="passwordIcon" class="icon"></i>
        </button>
      </div>
      <div>
        <p class="sans">
          <small>
            <a
              (click)="modalClose.emit()"
              (keyup.enter)="modalClose.emit()"
              [routerLink]="['/forgot-password']"
              class="forgot"
              data-cy="password-recovery"
              >{{ t('forgotYourPassword') }}</a
            >
          </small>
        </p>
        <div class="form-field">
          <label class="checkbox" for="keepLogged">
            <input
              data-cy="keepLogged"
              formControlName="keepLogged"
              id="keepLogged"
              name="keepLogged"
              type="checkbox"
            />
            <span data-cy="remember-me">{{ t('rememberMe') }}</span>
          </label>
        </div>
      </div>
      <ess-button (buttonClicked)="onSubmit()" [buttonType]="'submit'" class="submit" data-cy="btn-submit">{{
        t('signIn')
      }}</ess-button>
    </form>
  </div>
  <hr />
  <article class="magic-link-container">
    <div [@slideInOutLeft]="showForm ? 'hidden' : 'visible'">
      @if (!showForm) {
        <h3 class="heading-m">{{ t('longPasswordHardToType') }}</h3>
        <p class="sans"
          ><small>{{ t('emailMagicLink') }}</small></p
        >
        <ess-button buttonType="button" class="inverted" (click)="showForm = !showForm">{{
          t('requestMagicLink')
        }}</ess-button>
      }
    </div>

    <div [@slideInOutRight]="showForm ? 'visible' : 'hidden'">
      @if (showForm && !formSubmitted) {
        <form [formGroup]="magicLinkLogin" (ngSubmit)="sendMagicLink(magicLinkLogin)">
          <div class="form-field">
            <input formControlName="email" type="email" placeholder="{{ t('email') }}" [attr.aria-label]="t('email')" />
          </div>
          <ess-button buttonType="submit" [disabled]="magicLinkLogin.invalid">{{ t('next') }}</ess-button>
        </form>
      } @else {
        <div class="final-container">
          <h3 class="heading-m">{{ t('pleaseCheckYourEmail') }}</h3>
          <p class="sans"
            ><small>{{ t('weHaveSentYouALink') }}</small></p
          >
        </div>
      }
    </div>
  </article>
</ng-container>
