import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { catchError, switchMap, take, tap } from 'rxjs';
import { AuthService, HistoryState, IconService } from '@ess-front/shared';
import { ButtonComponent } from '@shared/button/button.component';
import { SafeHtml } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { UserDomainService } from '@app/domain/user-domain.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location, NgClass } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';
import { MagicLinkDomainService } from '@domain/magic-link-domain.service';

@Component({
  selector: 'ess-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, ButtonComponent, RouterModule, NgClass, TranslocoDirective],
  animations: [
    trigger('slideInOutLeft', [
      state('visible', style({ visibility: 'visible', transform: 'translateX(0%)' })),
      state('hidden', style({ visibility: 'hidden', transform: 'translateX(-100%)' })),
      transition('hidden => visible', [animate('300ms ease-in')]),
      transition('visible => hidden', [animate('300ms ease-in')]),
    ]),
    trigger('slideInOutRight', [
      state('visible', style({ visibility: 'visible', transform: 'translateX(-100%)' })),
      state('hidden', style({ visibility: 'hidden', transform: 'translateX(0%)' })),
      transition('hidden => visible', [animate('300ms ease-in')]),
      transition('visible => hidden', [animate('300ms ease-in')]),
    ]),
  ],
})
export class LoginFormComponent {
  email = '';
  formSubmitted = false;
  magicLinkLogin: FormGroup;
  passwordIcon: SafeHtml | null;
  showForm = false;
  showPassword = false;
  @Input() isModal = false;
  @Output() loginDone = new EventEmitter();
  @Output() modalClose = new EventEmitter();

  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
    keepLogged: new FormControl(false),
  });

  constructor(
    private router: Router,
    private authService: AuthService,
    private fb: FormBuilder,
    private iconService: IconService,
    private location: Location,
    private magickLinkDomainService: MagicLinkDomainService,
    private userDomainService: UserDomainService,
  ) {
    this.passwordIcon = this.iconService.getSVG('eye');
    this.magicLinkLogin = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  onSubmit() {
    const { email, password, keepLogged } = this.loginForm.value;
    if (email && password) {
      this.authService
        .login(email, password, keepLogged ?? false, this.isModal ? null : '/members-area')
        .pipe(
          tap(() => this.loginDone.emit()),
          switchMap(() => this.userDomainService.getUser$()),
          take(1),
          catchError(async () => this.loginForm.setErrors({ invalid: true })),
        )
        .subscribe();
    } else {
      this.loginForm.setErrors({ invalid: true });
    }
  }

  sendMagicLink(login: FormGroup) {
    this.formSubmitted = true;
    this.email = login.value.email;
    const redirectUrl = this.getRedirectUrl();
    this.magickLinkDomainService.sendEmail(this.email, redirectUrl);
  }

  private getRedirectUrl(): string | null {
    const redirectUrl: string | undefined = this.isModal
      ? this.router.url
      : (this.location.getState() as HistoryState).redirectUrl;
    if (redirectUrl && !redirectUrl.includes('login')) {
      return redirectUrl;
    } else {
      return null;
    }
  }
}
